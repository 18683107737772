import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static values = { frameName: String }
  static targets = []

  disconnect(){
  }

  connect(){
    this.form = this.element;
  }

  updateFrame(e){
    e.preventDefault();
    const data = new FormData(this.form);
    const qs = new URLSearchParams(data).toString();
    const frame = document.querySelector(`turbo-frame#${this.frameNameValue}`);
    console.log(frame)
    console.log(`${this.form.action}?${qs}`)
    // frame.src = `${this.form.action}?${qs}`;

  }

}
