import { Controller } from '@hotwired/stimulus'; 
import { tns } from 'tiny-slider'; 

export default class extends Controller {

  static targets = []; 
  static values = {
    options: Object
  }

  initialize() {
    const defaults = {
      container: this.element,
      touch: true
    }; 
    const args = Object.assign(defaults, this.optionsValue); 
    this.carousel = tns(args); 
    this.carousel.events.on('indexChanged', () => {
    });
    this.element.classList.remove('carousel--loading'); 
    this.element.dataset.behaviorLoaded = true; 
  }
}