import { Controller } from '@hotwired/stimulus'; 
import { get } from '@rails/request.js'; 

export default class extends Controller {

  static targets = ['message', 'input']; 
  static values = {
    disabled: Boolean,
    serviceUrl: String
  }

  connect() {
    const messagePlaceholder = this.messageTarget; 
    this.inputTarget.addEventListener('change', () => {
      if(!this.disabledValue && !messagePlaceholder.dataset.fetched){
        messagePlaceholder.classList.remove('hidden'); 
        get(this.serviceUrlValue, {
          contentType: 'application/json',
          responseKind: 'json'
        }).then((response) => {
          if(!response.ok){
            messagePlaceholder.innerHTML = `Impossible d'afficher l'estimation, veuillez réessayer plus tard.`; 
          } else {
            return response.json; 
          }
        }).then(json => { 
          messagePlaceholder.innerHTML = json.preview;
          messagePlaceholder.dataset.fetched = true; 
        }); 

      }  
    }); 
  }

}