import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.initiate_session_creation()
  }

  fetch(url, method = 'GET', data = '', options = {}) {
    let bodyData = {}
    if (data){
      bodyData = { body: data }
    }

    return fetch(url, Object.assign({}, {
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name=csrf-token]').content,
        "Content-Type": "application/json"
      }
    }, {
      method: method,
      ...options,
      ...bodyData
    })).then(
      (request) => {
        if (request.ok) {
          return request
        } else {
          const error = new Error(request.statusText)
          error.response = request
          throw error
        }
      },
    )
  }

  initiate_session_creation() {
    this.fetch(this.element.dataset.creationUrl, "POST")
      .then(this.start_polling.bind(this))
      .catch((error) => { console.log("error", error) })
  }

  start_polling() {
    this.intervalId = setInterval(this.poll.bind(this), 2000); 
  }

  poll() {
    this.fetch(this.element.dataset.statusUrl).then((response) => {
      const contentType = response.headers.get('Content-Type') || ''
      if (contentType.includes('application/json')) {
        return response.json()
      } else {
        throw new Error("Content is not JSON")
      }
    }).then((json) => {

      if (json.stripe_session_id) {
        clearInterval(this.intervalId)

        var stripe = Stripe(this.element.dataset.stripeKey, {
          stripeAccount: this.element.dataset.stripeAccountId
        });

        stripe.redirectToCheckout({
          sessionId: json.stripe_session_id
        }).then((result) => {
          console.log(result)
        }, (error) => {
          console.log(error)
        })

      }
    }).catch((error) => { console.log("error", error) })
  }

}