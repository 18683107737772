import { Controller } from "@hotwired/stimulus";
// import Tribute from "tributejs"
// import Trix from "trix"

export default class extends Controller {
  // static targets = [ "field" ]

  // connect() {
  //   this.editor = this.fieldTarget.editor
  //   this.initializeTribute()
  // }

  // disconnect() {
  //   this.tribute.detach(this.fieldTarget)
  // }

  // initializeTribute() {
  //   this.tribute = new Tribute({
  //     allowSpaces: true,
  //     lookup: "display_name",
  //     values: this.fetchStudents,
  //   })
  //   this.tribute.attach(this.fieldTarget)
  //   this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
  //   this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  // }

  // fetchStudents(text, callback) {
  //   fetch(`/mentions.json?query=${text}`)
  //   .then(response => response.json())
  //   .then(students => callback(students))
  //   .catch(error => callback([]))
  // }

  // replaced(event) {
  //   let mention = event.detail.item.original
  //   let attachment = new Trix.Attachment({
  //     sgid: mention.sgid,
  //     content: mention.content
  //   })
  //   this.editor.insertString(" ")
  //   this.editor.insertAttachment(attachment)
  //   this.editor.insertString(" ")
  // }

  // _pasteHtml(html, startPos, endPos) {
  //   let position = this.editor.getPosition()
  //   this.editor.setSelectedRange([position - endPos + startPos, position + 1])
  //   this.editor.deleteInDirection("backward")
  // }
}
