import * as tus from 'tus-js-client';
import { Controller } from '@hotwired/stimulus'
import Plyr from 'plyr/dist/plyr.polyfilled.js'

export default class extends Controller {

  static targets = ['player', 'inputSource', 'inputDestination', 'inputCoverImage', 'progress', 'error', 'cover']

  disconnect(){
  }

  connect(){
    // const player = this.playerTarget;
    const error = this.errorTarget;
    const tusEndpoint = '/tus-uploads'
    const routes = JSON.parse(this.element.dataset.tusRoutes)
    const translations = JSON.parse(this.element.dataset.tusTranslations)
    const elements = {
      bar: this.progressTarget.querySelector('[data-tus-progress="bar"]'),
      progress: this.progressTarget.querySelector('[data-tus-progress="progress"]'),
      litteral: this.progressTarget.querySelector('[data-tus-progress="litteral"]')
    }

    this.inputSourceTarget.addEventListener('change', function(e) {
      this.progressTarget.classList.remove('hidden');
      const file = e.target.files[0]
      const upload = new tus.Upload(file, {
        endpoint: tusEndpoint,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type
        },
        onError: function(error) {
          error.innerHTML = translations.error + '<br />' + error;
          error.classList.remove('hidden')
        },
        onProgress: function(bytesUploaded, bytesTotal) {
          const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
          elements.litteral.innerHTML = percentage + '%'
          elements.bar.style.width = percentage + '%';
        },
        onSuccess: function() {
          error.classList.add('hidden')
          const uid = upload.url.split(tusEndpoint + '/')[1];
          fetch(routes.upload_info + `?uid=${uid}&file_type=${upload.file.type}`)
            .then(response => response.json())
            .then(data => {

              const source = document.createElement('source')
              source.setAttribute('src', data.url)
              source.setAttribute('type', data.content_type)
              this.playerTarget.innerHTML = '';
              this.playerTarget.appendChild(source)
              this.playerTarget.appendChild(document.createTextNode(`Sorry, your browser doesn't support embedded videos 😭.`))
              this.playerTarget.classList.remove('hidden')
              this.inputDestinationTarget.value = uid;

              elements.bar.classList.remove('bg-gray-200')
              elements.bar.classList.add('bg-indigo-500')
              // elements.progress.classList.add('bg-green-500')

            })
        }.bind(this)
      });
      upload.findPreviousUploads().then(function (previousUploads) {
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0])
        }
        upload.start()
      })
    }.bind(this))
  }

  // _extractScreenshotFromVideo(videoEl, scale) {
  //   scale = scale || 1;
  //   const canvas = document.createElement('canvas');
  //   canvas.width = videoEl.clientWidth * scale;
  //   canvas.height = videoEl.clientHeight * scale;
  //   canvas.getContext('2d').drawImage(videoEl, 0, 0, canvas.width, canvas.height);
  //   const image = new Image()
  //   image.src = canvas.toDataURL();
  //   this.coverTarget.appendChild(image)
  //   console.log('done')
  //   return canvas.toDataURL('image/jpeg');
  // }


}
