import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.start_polling()
  }

  fetch(url, method = 'GET', data = '', options = {}) {
    let bodyData = {}
    if (data){
      bodyData = { body: data }
    }

    return fetch(url, Object.assign({}, {
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name=csrf-token]').content,
        "Content-Type": "application/json"
      }
    }, {
      method: method,
      ...options,
      ...bodyData
    })).then(
      (request) => {
        if (request.ok) {
          return request; 
        } else {
          const error = new Error(request.statusText)
          error.response = request; 
          throw error; 
        }
      },
    )
  }

  start_polling() {
    this.intervalId = setInterval(this.poll.bind(this), 2000); 
  }

  poll() {
    this.fetch(this.element.dataset.statusUrl).then((response) => {
      const contentType = response.headers.get('Content-Type') || ''; 
      if (contentType.includes('application/json')) {
        return response.json(); 
      } else {
        throw new Error("Content is not JSON"); 
      }
    }).then((json) => {
      if (this.element.dataset.verify && json.is_stripe_connected) {
        clearInterval(this.intervalId)
        window.location = this.element.dataset.nextUrl; 
        return; 
      }
      if (this.element.dataset.create && json.stripe_account_link) {
        clearInterval(this.intervalId); 
        window.location = json.stripe_account_link; 
        return; 
      }

      if (json.updated_at && json.updated_at != this.element.dataset.updatedAt) {
        clearInterval(this.intervalId); 
        window.location = this.element.dataset.errorUrl; 
      }
    }).catch((error) => { console.log("error", error); }); 
  }

}