import { Controller } from '@hotwired/stimulus'; 
import { isBlank, isFalsy, isTruthy } from '@javascript/javascripts/utils'; 
import * as tus from 'tus-js-client';
import Dropzone from 'dropzone';


export default class extends Controller {

  static values = {
    feedbackId: Number,
    formElementSelector: String,
    targetElementSelector: String,
    createVimeoFileServiceUrl: String,
    validationServiceUrl: String,
    onDragStartStyle: String,
    fileInvalidTypeError: String,
    fileSizeLimitError: String,
    fileSizeLimit: Number,
    fileNamePrefix: String
  }

  static targets = [
    'loadingIndicator',
    'wrapper',
    'uploader',
    'placeholder',
    'success',
    'successMessage',
    'errorMessage',
    'removeFilesLink',
    'progressWrapper',
    'progressPercentage',
    'progressBar',
    'videoPlayer'
  ]; 

  connect(){
    this.fileToUpload = null;
    this.uploadCompleted = false;
    this.form = document.querySelector(this.formElementSelectorValue);
    this.targetElement = document.querySelector(this.targetElementSelectorValue);
    this.loadDropzone();

  }

  upload(){
    if(this.fileToUpload){
      const file = this.fileToUpload;
      const fileName = isBlank(this.fileNamePrefixValue) ? file.name : `${this.fileNamePrefixValue}${file.name}`;
      this.form.querySelector('[type="submit"]').setAttribute('disabled', 'disabled'); 

      const body = JSON.stringify({
        feedback_id: this.feedbackIdValue,
        file_name: fileName,
        file_size: file.size
      });
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }; 
      this.progressWrapperTarget.classList.remove('hidden');

      fetch(this.createVimeoFileServiceUrlValue, {
        method: 'POST',
        headers: headers,
        body: body
      }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response.json(); 
      })
      .then(data => {
        const upload = new tus.Upload(file, {
          uploadUrl: data.upload['upload_link'],
          metadata: {
            filename: fileName,
            filetype: file.type
          },
          uploadSize: file.size,
          chunkSize: 5000000,
          onError: function(error) {
            console.log("Failed because: " + error);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
            this.removeFilesLinkTarget.classList.add('hidden');
            this.progressPercentageTarget.innerText = `${percentage}%`; 
            this.progressBarTarget.style.width = `${percentage}%`;
          },
          onSuccess: () => {
            this.targetElement.value = `https://vimeo.com/${data.video_id}`;
            this.uploadCompleted = true;
            this.form.requestSubmit();
            // console.log("Download %s from %s", upload.file.name, upload.url)
          }
        })
        upload.start(); 
      })
      .catch((error) => {
        console.log(error);
        this.errorMessageTarget.innerHTML = error;
      });
    }
  }

  loadDropzone(){
    Dropzone.autoDiscover = false;
    const fileMaxWeight = this.fileSizeLimitValue;
    const activeStyles = this.onDragStartStyleValue.split(' ');
    const defaultStyles = this.uploaderTarget.getAttribute('class'); 

    const resetMessages = (props = {}) => {
      if(!props.preserveError){
        this.errorMessageTarget.innerText = '';
      }
      this.successTarget.classList.add('hidden'); 
      this.placeholderTarget.classList.remove('hidden');
      this.removeFilesLinkTarget.classList.add('hidden');
    }; 
    const resetStyles = () => { this.uploaderTarget.setAttribute('class', defaultStyles) }; 
    const addActiveStyles = () => { activeStyles.map(style => this.uploaderTarget.classList.add(style)); };
    const removeActiveStyles = () => { activeStyles.map(style => this.uploaderTarget.classList.remove(style)); };
    const showRemoveFilesLink = () => { this.removeFilesLinkTarget.classList.remove('hidden'); };
    const dropzone = new Dropzone(this.element, {
      url: '/',
      autoProcessQueue: false,
      maxFiles: 1,
      acceptedFiles: 'video/*',
      clickable: this.uploaderTarget,
      disablePreviews: true,
      init: function() {
        this.wrapperTarget.classList.remove('hidden');
        this.loadingIndicatorTarget.classList.add('hidden');
      }.bind(this)
    });

    this.removeFilesLinkTarget.addEventListener('click', () => {
      dropzone.removeAllFiles(true);
      resetMessages();
    });

    dropzone.on('dragenter', file => {
      addActiveStyles();
    });

    dropzone.on('addedfile', file => {
      removeActiveStyles();
      resetMessages();

      if(!file.type.startsWith('video/')){
        this.errorMessageTarget.innerText = this.fileInvalidTypeErrorValue;
        dropzone.removeAllFiles(true);
        resetMessages({ preserveError: true });
      } else if (file.size > fileMaxWeight) {
        this.errorMessageTarget.innerText = this.fileSizeLimitErrorValue;
        dropzone.removeAllFiles(true);
        resetMessages({ preserveError: true });
      } else {
        showRemoveFilesLink();
        resetStyles(); 

        this.placeholderTarget.classList.add('hidden');
        this.successTarget.classList.remove('hidden');
        this.successMessageTarget.innerText = `${file.name} (${(file.size / 1000000).toFixed(2)} mo)`; 

        this.fileToUpload = file;
        // this.form.querySelector('[type="submit"]').classList.add('btn--primary');
        this.formSubmitEventListener = this.form.addEventListener('submit', (e) => {
          if(!this.uploadCompleted){
            e.preventDefault();
            e.stopImmediatePropagation();
            this.upload();
          }
        });

      }
    });
  }

}
