import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = { 
    target: String,
    enabled: Boolean
  }

  connect(){
    if(this.enabledValue){
      this.interval = setInterval(() => {
        !this.enabledValue ? clearInterval(this.interval) : this._refresh(); 
      }, 2500);
    }
  }

  _refresh(){
    clearInterval(this.interval);
    if('window' === this.targetValue){
      Turbo.clearCache();
      Turbo.visit(document.location.pathname);
    } else {
      document.querySelector(`turbo-frame#${this.targetValue}`).reload(); 
    }
  }

}
