import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'; 

export default class extends Controller {

  connect() {
    this.element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}); 
  }
  
  // static targets = [
  //   'successIcon',
  //   'errorIcon', 
  //   'videoStateBadges'
  // ]
  // static values = {}

  // connect(){
  // }
  
  // _showSuccessIcon(){
  //   this.successIconTarget.classList.remove('hidden')
  //   const player = this.successIconTarget.querySelector("lottie-player")
  //   player.load(player.src)
  // }
  // _showErrorIcon(){
  //   this.errorIconTarget.classList.remove('hidden')
  //   const player = this.errorIconTarget.querySelector("lottie-player")
  //   player.load(player.src)
  // }

  // _updatePlaylistBadge(select){
  //   const badgeHTML = this.videoStateBadgesTarget.querySelector(`[data-video-state-id="${select.value}"]`).innerHTML;
  //   document.querySelector(`[data-playlist-video-state-badge="${select.dataset.videoId}"`).innerHTML = badgeHTML; 
  // }

  // submit(e){
  //   const select = e.target;
  //   post(select.form.action, {
  //     body: { progression: { video_state_id: select.value } },
  //     contentType: 'application/json',
  //     responseKind: 'json'
  //   }).then((response) => {
  //     if(!response.ok){
  //       this._showErrorIcon(); 
  //     } else {
  //       this._showSuccessIcon(); 
  //       this._updatePlaylistBadge(select); 
  //       return response.json; 
  //     }
  //   }).then(json => {  
  //     console.log(json)
  //   }); 

  // }

}
