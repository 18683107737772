import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  // static targets = ['container', 'pager', 'clonedPager']; 
  // static values = { }; 

  connect(){
    // this.element.addEventListener('turbo:before-fetch-response', (e) => {
    //   // e.preventDefault();
      
    //   // return e.detail.fetchResponse.response.text().then(text => {
    //   //   this.containerTarget.insertAdjacentHTML('beforeend', text);
    //   //   const frames = this.containerTarget.querySelectorAll('turbo-frame')

    //   //   if(frames.length > 1){
    //   //     frames[0].remove(); 
    //   //   }
    //   //   if(this.containerTarget.querySelector('[data-pagination-end]')){
    //   //     this.containerTarget.querySelector('[data-pagination-end]').remove(); 
    //   //     frames[0].remove(); 
    //   //   }

    //   // }); 
    // });
  }
}

// manual view more
// if(this.hasPagerTarget){
//   this.clonedPagerTarget.innerHTML = this.pagerTarget.innerHTML;
//   this.pagerTarget.parentNode.removeChild(this.pagerTarget);
// } else {
//   this.clonedPagerTarget.parentNode.removeChild(this.clonedPagerTarget);
// }
