import { Controller } from '@hotwired/stimulus'; 
import '@yaireo/tagify/dist/tagify.css';
import Tagify from '@yaireo/tagify'; 

export default class extends Controller {

  static values = {
    whitelist: String,
    whitelistServiceUrl: String,
  }

  disconnect(){
    this.tagify.destroy(); 
  }

  connect(){
    const input = this.element;
    const tagify = new Tagify(input, {
      mode : 'select',
      whitelist: [],
      keepInvalidTags: false,
      templates : {
        tag : function(tagData){
          try{
            return `
            <tag title='${tagData.title}' contenteditable='false' spellcheck="false" class='tagify__tag ${tagData.class ? tagData.class : ""}' ${this.getAttributes(tagData)}>
              <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
              <div>
                <span class='tagify__tag-text'>${tagData.title}</span>
              </div>
            </tag>`; 
          }
          catch(err){
            console.log(err); 
          }
        },
        dropdownItem : function(tagData){
          try{
            if(tagData.course_url){
              return `
                <div data-course-url="${tagData.course_url}" class='tagify__dropdown__item ${tagData.class ? tagData.class : ""}'>
                  <div class="flex items-center">
                    <div class="flex-grow">
                      <p class="tagify__dropdown__item__title">${tagData.title}</p>
                      <p class="tagify__dropdown__item__description">${tagData.discipline_name} - ${tagData.level_name} - ${tagData.description}</p>
                    </div>
                  </div>
                </div>`; 
              }
            }
          catch(err){}
        }
      },
      dropdown: {
        closeOnSelect: true
      }
    });

    tagify.on('dropdown:select', (e) => {
      const url = e.detail.elm.dataset.courseUrl; 
      if(url){
        window.location.replace(url); 
      }
    });

    tagify.DOM.input.addEventListener('input', () => {
      if(tagify.whitelist.length == 0){
        tagify.loading(true).dropdown.hide.call(tagify);
        fetch(this.whitelistServiceUrlValue).then(response => response.json()).then((result) => {
          result.map(r => r.value = r.title); 
          tagify.settings.whitelist = result;
          tagify.loading(false).dropdown.show(e.detail.value);
        }).catch(err => {
          tagify.loading(false).dropdown.hide();
        })
      }
    });
    this.tagify = tagify;
  }
}

    // this.tagify = new Tagify(this.element, {
    //   delimiters: null,
    //   mode: "select",
    //   keepInvalidTags: false,
    //   dropdown : {
    //     enabled: 1, // suggest tags after a single character input
    //     fuzzySearch: true,
    //     classname : 'extra-properties' // custom class for the suggestions dropdown
    //   },
    //   enforceWhitelist: true,
    //   templates : {
    //     tag : function(tagData){
    //       try{
    //         return `
    //         <tag title='${tagData.title}' contenteditable='false' spellcheck="false" class='tagify__tag ${tagData.class ? tagData.class : ""}' ${this.getAttributes(tagData)}>
    //           <x title='remove tag' class='tagify__tag__removeBtn'></x>
    //           <div>
    //             ${tagData.code ? `<img onerror="this.style.visibility='hidden'" src='https://lipis.github.io/flag-icon-css/flags/4x3/${tagData.code}.svg'>` : ''}
    //             <span class='tagify__tag-text'>${tagData.title}</span>
    //           </div>
    //         </tag>`
    //       }
    //       catch(err){
    //         console.log(err)
    //       }
    //     },
    //     dropdownItem : function(tagData, o){
    //       try{
    //         return `
    //           <div class='tagify__dropdown__item ${tagData.class ? tagData.class : ""}'>
    //             <span>${tagData.title}</span>
    //           </div>`
    //         }
    //       catch(err){}
    //     }
    //   },
    //   whitelist: []
    // })

    // if(this.tagify){
    //   const tagify = this.tagify;
    //   const fetchTags = (e) => {
    //     if(tagify.settings.whitelist.length == 0){
    //       tagify.loading(true).dropdown.hide.call(tagify);
    //       fetch(whitelistUrl)
    //         .then(response => response.json())
    //         .then((result) => {
    //           tagify.settings.whitelist = result;
    //           tagify.loading(false).dropdown.show(e.detail.value);
    //         }).catch(err => {
    //           tagify.dropdown.hide();
    //         })
    //     } else {
    //       tagify.dropdown.show(e.detail.value)
    //     }
    //   }
    //
    //   this.tagify.on('input', fetchTags)
    //   // tagify.on('focus', fetchTags);
    //   // tagify.on('click', fetchTags);
    //
    //   this.tagify.on('add', (e) => {
    //     console.log( "original Input:", tagify.DOM.originalInput);
    //     console.log( "original Input's value:", JSON.parse(tagify.DOM.originalInput.value));
    //   });
    // }


//   }
//
// }

// <img onerror="this.style.visibility = 'hidden'" src='https://lipis.github.io/flag-icon-css/flags/4x3/${tagData.code.toLowerCase()}.svg'>

// <p class="flex-shrink-0 mr-2"><img src="${tagData.cover_url}" class="h-10 w-10 rounded" /></p>