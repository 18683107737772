import { Controller } from '@hotwired/stimulus'
import Plyr from 'plyr/dist/plyr.polyfilled.js'

export default class extends Controller {

  static targets = ['player']

  disconnect(){
    // this.playerTargets.map(p => {
    //   const instance = new Plyr(p)
    //   instance.destroy();
    // })
  }

  initialize(){
    this.players = [];
    Array.from(this.playerTargets).map(element => {
      this.players.push(new Plyr(element))
    })

  }
}
