import { Controller } from '@hotwired/stimulus';
import { addClass, isBlank } from '@javascript/javascripts/utils.js'; 
// import '@javascript/javascripts/plyr/plyr.css'; 
import Plyr from 'plyr'; 


export default class extends Controller {

  static get targets() {
    return [
      'player', 
      'iframe', 
      'extraControls',
      'loopInputEndTime', 
      'loopInputStartTime',
      'loopControlCancel',
      'loopControlApply',
      'markerText'
    ]; 
  }


  connect() {
    this.playerOptions = JSON.parse(this.element.dataset.playerOptions); 
    this._loadPlayer();
  }

  applyLoop(e){
    e.preventDefault();
    if(this._validateLoopRange()){
      this.player.play(); 
      this._setLoopRange();
      this.player.currentTime = this.loopStartAt;
      this.loopStartAt && this.loopEndAt ? this.loopControlCancelTarget.removeAttribute('hidden') : this.loopControlCancelTarget.setAttribute('hidden', 'hidden'); 
      this.player.off('timeupdate', this._rewindLoop.bind(this)); 
      this.player.on('timeupdate', this._rewindLoop.bind(this)); 
      this.forceLoopRangeUpdate = this.loopState != undefined && this.loopState != `${this.loopStartAt}-${this.loopEndAt}`; 
      this.loopState = `${this.loopStartAt}-${this.loopEndAt}`; 
      this._decorateLoopStateActive(); 
    }
  }

  _decorateLoopStateActive(){
    this.loopControlApplyTarget.classList.add('hidden');
    this.loopControlCancelTarget.classList.remove('hidden');
    
    this.loopInputStartTimeTarget.classList.add('animate-pulse'); 
    this.loopInputEndTimeTarget.classList.add('animate-pulse');
  }

  _decorateLoopStateInactive(){
    this.loopControlApplyTarget.classList.remove('hidden');
    this.loopControlCancelTarget.classList.add('hidden');
    
    this.loopInputStartTimeTarget.classList.remove('animate-pulse'); 
    this.loopInputEndTimeTarget.classList.remove('animate-pulse');
  }

  cancelLoop(e){
    e.preventDefault(); 
    this.player.off('timeupdate', this._rewindLoop.bind(this)); 
    this.loopStartAt = null;
    this.loopEndAt = null; 
    this.loopInputEndTimeTarget.value = null; 
    this.loopInputStartTimeTarget.value = null; 
    this.loopControlCancelTarget.classList.add('hidden');
    this.loopControlApplyTarget.classList.remove('hidden');
    this._decorateLoopStateInactive(); 
  }

  seekToMarker(e){
    if(!this.player.playing){
      this.player.play();
      this.player.currentTime = parseInt(e.target.value, 10);
    } else {
      this.player.currentTime = parseInt(e.target.value, 10);
    }
    this.markerTextTarget.innerHTML = e.target.options[e.target.selectedIndex].text + ' ✅';
    setTimeout(() => { this.markerTextTarget.innerHTML = ''; }, 2000); 
    e.target.selectedIndex = 0;
  }

  _rewindLoop(){
    if(this.forceLoopRangeUpdate){
      this._setLoopRange();
      this.forceLoopRangeUpdate = false; 
    } else {
      if((this.loopStartAt && this.loopEndAt) && (this.player.currentTime > this.loopEndAt)){
        this.player.currentTime = this.loopStartAt; 
      }
    }
  }

  _setLoopRange(){
    this.loopStartAt = this._timeToSeconds(this.loopInputStartTimeTarget.value); 
    this.loopEndAt = this._timeToSeconds(this.loopInputEndTimeTarget.value); 
  }

  _validateLoopRange(){
    let hasError = false; 
    if(isBlank(this.loopInputStartTimeTarget.value)){
      hasError = true; 
      addClass(this.loopInputStartTimeTarget, ['ring-2', 'ring-red-400']); 
    }
    if(isBlank(this.loopInputEndTimeTarget.value)){
      hasError = true; 
      addClass(this.loopInputStartTimeTarget, ['ring-2', 'ring-red-400']); 
    }
    if(this._timeToSeconds(this.loopInputEndTimeTarget.value) < this._timeToSeconds(this.loopInputStartTimeTarget.value)){
      addClass(this.loopInputEndTimeTarget, ['ring-2', 'ring-red-400']);
      hasError = true;
    }
    return !hasError; 
  }

  _loadPlayer(){
    if(this.hasIframeTarget){
      const defaults = {
        hd: true,
        clickToPlay: true,
        loop: { active: true },
        vimeo: { byline: false, portrait: false, title: false, speed: true, transparent: false }, 
        youtube: { modestbranding: 1, autohide: 1, showinfo: 0, controls: 0, iv_load_policy: 3 },
        markers: { enabled: true }
      };
      const options = { ...defaults, ...this.playerOptions }; 
      window.videoPlayers = window.videoPlayers || []; 
      this.playerTarget.classList.add('plyr__video-embed');
      this.player = new Plyr(this.playerTarget, options);
      this.player.on('play', () => { 
        if(this.hasExtraControlsTarget){
          this.extraControlsTarget.classList.remove('hidden');
        }
      });
      window.videoPlayers.push(this.player); 
    }
  }

  _timeToSeconds(time){
    const a = time.toString().split(':'); 
    return a.length > 1 ? (+a[0]) * 60 + (+a[1]) : +a[0];
  }


  // this.controlLoopStartTarget.addEventListener('input', () => {
  //   if(this._timeToSeconds(this.controlLoopStartTarget.value) > this.player.duration){
  //     this.controlLoopStartTarget.value = 0
  //   }
  // }); 

  // this.controlLoopEndTarget.addEventListener('input', () => {
  //   if(this._timeToSeconds(this.controlLoopEndTarget.value) > this.player.duration){
  //     this.controlLoopEndTarget.value = this.controlLoopStartTarget.value
  //   }
  // }); 




}



//     // this.cssPrefix = `c--video-player`; 
//     // this.buildPlayer();

//     // this.player.on('ready', () => {
//     //   if(this.data.get('markers')){
//     //     this.initializeMarkers(JSON.parse(this.data.get('markers')))
//     //   }
//     //   this.handleTracking();
//     // });

//     // this.setSelectedStyleControlSpeed();
//   }

//   buildPlayer(){
//     this.player = new Plyr(this.playerTarget, {
//       controls: this.controlsTarget.firstChild,
//       invertTime: false
//     })
//   }

//   toggleLoopMenu(){
//     if(this.loopMenuTarget.getAttribute('hidden') === 'hidden'){
//       this.loopMenuTarget.removeAttribute('hidden')
//       this.controlLoopStartTarget.focus()
//     } else {
//       this.loopMenuTarget.setAttribute('hidden', 'hidden')
//     }
//   }



//   setSelectedStyleControlSpeed(){
//     this.speedControlTargets[this.speedControlTargets.length - 1].classList.add(this._cssClass('speed--selected'))
//     this.player.on('ratechange', () => {
//       Array.from(this.speedControlTargets).map(control => {
//         if(parseFloat(control.value, 10) == this.player.speed){
//           control.setAttribute('aria-checked', true)
//           control.classList.add(this._cssClass('speed--selected'))
//         } else {
//           control.setAttribute('aria-checked', false)
//           control.classList.remove(this._cssClass('speed--selected'))
//         }
//       })
//     })
//   }

//   setSpeed(e){
//     const trigger = e.target.parentNode; 
//     trigger.setAttribute('aria-checked', true)
//     this.player.speed = parseFloat(trigger.getAttribute('value'), 10)
//   }

//   initializeMarkers(markers){
//     let set = false
//     this.player.on('play', () => {
//       if(!set){
//         markers.forEach(props => {
//           const at = this._timeToSeconds(props.time);
//           const pct = at * 100 / this.player.duration;
//           const marker = document.createElement('a')

//           marker.classList.add(this._cssClass('marker'))
//           marker.style.left = `${pct}%`
//           marker.innerHTML = this._icon('chat', 'w-5 h-5')
//           this.progressBarTarget.appendChild(marker)

//           tippy(marker, { content: props.text })
//           marker.addEventListener('click', e => {
//             e.preventDefault()
//             this.player.currentTime = at
//           })
//           set = true
//         })
//       }
//     })
//   }

//   _cssClass(name){
//     return `${this.cssPrefix}__${name}`
//   }
//   _timeToSeconds(time){
//     const a = time.toString().split(':')
//     return a.length > 1 ? (+a[0]) * 60 + (+a[1]) : +a[0];
//   }

//   _icon(type, classes){
//     const icons = {
//       chat: `<svg viewBox="0 0 20 20" fill="currentColor" class="chat ${classes || "w-6 h-6"}"><path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd"></path></svg>`,
//       annotation: `<svg viewBox="0 0 20 20" fill="currentColor" class="annotation ${classes || "w-6 h-6"}"><path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>`
//     }
//     return icons[type]
//   }

//   handleTracking(options){
//     let elapsedSeconds = 0, completion = 0, cache = {};
//     this.player.on('timeupdate', () => {
//       elapsedSeconds = Math.round(this.player.currentTime);
//       completion = Math.round((this.player.currentTime * 100) / this.player.duration);
//       if(this.player.currentTime > 0){
//         // do something when completion == 5, 10, 15, 20..
//       }
//     });
//   }
// }
