import { secondsToTime, isBlank, isFalsy, isTruthy } from '@javascript/javascripts/utils'; 
import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller {

  static targets = [
    'spinner',
    'linkFillFields',
    'inputUrl', 
    'inputTitle', 
    'inputDescription', 
    'inputDurationLitteral',
    'inputCoverRemoteUrl', 
    'inputCoverFileUpload', 
    'coverPreview',
    'coverFields',
    'coverRemoteUrlImagePlaceholder', 
    // 'coverRemoteUrlCopier'
  ]

  connect(){
    setTimeout(() => {
      if(this.hasInputUrlTarget && !isBlank(this.inputUrlTarget.value)){
        this.showLinkFillFields();
      }
    }, 1500)
  }

  setCoverRemoteUrl(e){
    e.preventDefault();
    this.inputCoverRemoteUrlTarget.value = this.coverRemoteUrlImagePlaceholderTarget.src; 
  }
  // https://vimeo.com/676906900
  showLinkFillFields(e){
    if(this.inputUrlTarget.value.match(/vimeo\.com\/\d+/)){
      this.linkFillFieldsTarget.classList.remove('hidden');
    }
  }

  _setLoading(state){
    this.loading = state; 
    this.spinnerTarget.classList.toggle('hidden', !state);
    this.inputUrlTarget.toggleAttribute('disabled', state);
  }
  fetchMetadata(e){
    e.preventDefault(); 
    this._setLoading(true)


    const targets = {
      inputCoverRemoteUrlTarget: 'cover',
      inputTitleTarget: 'title',
      inputDescriptionTarget: 'description',
      inputDurationLitteralTarget: 'duration'
    }; 
    const serviceUrl = this.element.dataset.fetchUrl; 
    const params = new URLSearchParams({
      url: this.inputUrlTarget.value
    })

    fetch(serviceUrl + '?' + params, {
      method: 'GET'
    }).then((response) => response.json()).then((json) => {

      for (const [target, identifier] of Object.entries(targets)) {
        const value = json[identifier];
        const input = this[target]; 

        if(input && !isBlank(value)){
          switch (identifier) {
            case 'duration':
              input.value = secondsToTime(value); 
              break;
            case 'thumbnail_url':                
              // if(isFalsy(this.inputCoverFileUploadTarget.dataset.attached) && isBlank(input.value)){}

              this.coverFieldsTarget.classList.remove('hidden');
              this.coverPreviewTarget.classList.add('hidden');
              this.coverRemoteUrlImagePlaceholderTarget.src = value; 
              this.coverRemoteUrlImagePlaceholderTarget.classList.remove('hidden');
              this.inputCoverRemoteUrlTarget.value = this.coverRemoteUrlImagePlaceholderTarget.src;

              break;
            default:
              if(isBlank(input.value)){
                input.value = value; 
              }
          }
        }
      }
      this._setLoading(false); 

    });
  }



  // 561354873
  // console.log(e.target.value)

  // var myHeaders = new Headers();
  //
  // var myInit = { method: 'GET',
  //                headers: myHeaders,
  //                mode: 'cors',
  //                cache: 'default' };
  //
  // fetch('flowers.jpg',myInit)
  // .then(function(response) {
  //   return response.blob();
  // })
  // .then(function(myBlob) {
  //   var objectURL = URL.createObjectURL(myBlob);
  //   myImage.src = objectURL;
  // });

}
