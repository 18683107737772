import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller {

  connect(){
    import('autolinker').then(module => {
      const Autolinker = module.default; 
      var linkedText = Autolinker.link(this.element.innerHTML, { 
        stripPrefix: false
      });
      this.element.innerHTML = linkedText; 
    });
    // var linkedText = Autolinker.link( textToAutolink[, options] );
    // console.log(this.element.innerHTML);
  }
} 