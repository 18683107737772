import { Controller } from "@hotwired/stimulus";
import IMask from 'imask';

export default class extends Controller {

  connect() {
    const maskOptions = {
      mask: 'm:`s',
      // lazy: false,  // make placeholder always visible
      autofix: true,
      overwrite: true,
      eager: true,
      blocks: {
        m: { mask: IMask.MaskedRange, placeholderChar: 'm', from: 0, to: 59, maxLength: 2 },
        s: { mask: IMask.MaskedRange, placeholderChar: 's', from: 0, to: 59, maxLength: 2 }
      }
    }; 
    const mask = IMask(this.element, maskOptions);
    this.element.addEventListener('keydown', () => { mask.updateValue() }); 
  }

}
