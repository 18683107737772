import { Controller } from '@hotwired/stimulus';
import { addClass, removeClass } from '@javascript/javascripts/utils'; 

export default class extends Controller {

  static targets = ['input', 'label', 'message']; 

  disconnect(){
  }

  connect(){
    this.inputTarget.classList.add('hidden'); 
    this.inputTarget.addEventListener('change', (e) => { 
      const value = e.target.value; 
      if(value){
        this.messageTarget.innerHTML = this.inputTarget.files[0].name + '  ✅'; 
      }
    });
  }

}
