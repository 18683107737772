// import "@vendor/javascripts/request_submit.polyfill.js"

// import Rails from "@rails/ujs"
// import "@hotwired/turbo-rails"
// import * as ActiveStorage from "@rails/activestorage"
// import Alpine from "alpinejs"
// import "controllers"

// Rails.start()
// ActiveStorage.start()
// Alpine.start()


import { Turbo } from '@hotwired/turbo-rails';
import LazyLoad from "vanilla-lazyload";
var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});

Turbo.start();

import '../javascripts/request_submit.polyfill';
import '@lottiefiles/lottie-player';
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import "chartkick/chart.js";

// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import { Application } from '@hotwired/stimulus';
const application = Application.start();
application.debug = false;
window.Stimulus = application;

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading";
// eagerLoadControllersFrom("controllers", application);

import { registerControllers } from 'stimulus-vite-helpers';
const controllers  = import.meta.globEager('../**/*_controller.js');
const components = import.meta.globEager('../../components/**/*_controller.js');
registerControllers(application, {...controllers, ...components});
