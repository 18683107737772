import { isFalsy, isTruthy } from '@javascript/javascripts/utils'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  initialize() {
    // if(form.dataset.onchangeSubmit){
    //   form.addEventListener('change', () => { console.log('changed') })
    //   // const inputs = Array.from(form.querySelectorAll('input'));
    //   // inputs.map(input => input.addEventListener('change', () => {  console.log('changed!') }))
    //
    // }
    // this._applyClasses();
    this._applyAutofocus();
    this._applyReadOnlyDisabled();
  }

  get form(){
    return this.element;
  }

  get inputs(){
    return [].slice.call(this.form.elements);
  }

  _applyReadOnlyDisabled(){

    this.inputs.map(input => {
      if(isTruthy(input.getAttribute('disabled'))){
        input.parentNode.classList.add('field--disabled')
      }
    })

    // this.inputs.each(el => {
    // })
    //   console.log(el.getAttribute('disabled'))
    // })
  }

  _applyAutofocus(){
    if(window.innerWidth > 1024){
      if(this.form.querySelector('[data-autofocus]')){
        this.form.querySelector('[data-autofocus]').focus();
      }
    }
  }

  _applyClasses(){
  }

  reset() {
    const parent = this.element.parentNode;
    if(parent.getAttribute('data-after-reset') == 'hide'){
      parent.style.display = 'none'; 
    }
    this.element.reset(); 
    Array.from(this.element.elements).forEach((input) => {
      if(input.hasAttribute('autofocus')){
        input.focus(); 
      }
    });
  }

  submit() {
    this.element.requestSubmit();
  }

}
