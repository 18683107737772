import { Controller } from '@hotwired/stimulus'; 
import '@yaireo/tagify/dist/tagify.css';
import Tagify from '@yaireo/tagify'; 

export default class extends Controller {

  static values = { 
    whitelist: Array, 
    options: Object
  }

  disconnect(){
    if(this.tagify){
      this.tagify.destroy();
    }
  }

  connect(){
    const input = this.element;
    const args = {
      whitelist: this.whitelistValue,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    }; 
    if(this.optionsValue.preserveOriginalInputValueFormat){
      delete args.originalInputValueFormat; 
    }
    const tagify = new Tagify(input, args);
  }


}