const insertStylesheet = (source) => {
  window.loadedStylesheets = window.loadedStylesheets || {}; 
  if(!window.loadedStylesheets[source]){
    const modern = document.createElement('link');
    modern.setAttribute('rel', 'preload');
    modern.setAttribute('type', 'text/css');
    modern.setAttribute('as', 'style');
    modern.setAttribute('href', source);
    const legacy = document.createElement('link');
    legacy.setAttribute('rel', 'stylesheet');
    legacy.setAttribute('media', 'print');
    legacy.setAttribute('onload', 'this.media="all"');
    legacy.setAttribute('href', source);
    document.head.appendChild(modern);
    document.head.appendChild(legacy);
    window.loadedStylesheets[source] = true; 
  }
}; 

const insertScript = (parent, source, callbackOrEventName) => {
  window.loadedScripts = window.loadedScripts || {}
  if(!window.loadedScripts[source]){
    let script = document.createElement('script');
    let prior = document.getElementsByTagName('script')[0];
    script.async = 1;
    script.onload = script.onreadystatechange = function( _, isAbort){
      if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
        script.onload = script.onreadystatechange = null;
        script = undefined;
        if(typeof callbackOrEventName === 'string'){
          const event = new CustomEvent(`${callbackOrEventName}:ready`, {})
          document.dispatchEvent(event);
        } else {
          if(!isAbort && callbackOrEventName) setTimeout(callbackOrEventName, 200);
        }
      }
    };
    script.src = source;
    parent.appendChild(script)
    window.loadedScripts[source] = true; 
  } else {
    if(typeof callbackOrEventName === 'string'){
      setTimeout(() => {
        const event = new CustomEvent(`${callbackOrEventName}:ready`, {})
        document.dispatchEvent(event);
      }, 100); 
    } else {
      if(callbackOrEventName) { callbackOrEventName() }
    }
  }
};


const secondsToTime = (seconds) => {
  const d = Number(seconds);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);
  // var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  // var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  // var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return [h, m, s].map(i => prependZero(i)).join(':')
}
const prependZero = (number) => {
  return ('0' + number).slice(-2); 
}
const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}
const isBlank = (str) => {
  return (!str || /^\s*$/.test(str));
}

const addClass = (element, classes) => {
  classes.map(c => element.classList.add(c)); 
}

const removeClass = (element, classes) => {
  classes.map(c => element.classList.remove(c)); 
}

const isElementHidden = (element) => {
  return element.style.position === 'fixed' ?
    window.getComputedStyle(element).style.display === 'none' :
    element.classList.contains('hidden') || element.offsetParent === null; 
}

const deepMerge = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return deepMerge(target, ...sources);
}

const getElementHeight = (element) => {
  return parseFloat(getComputedStyle(element, null).height.replace("px", "")); 
}

const isInViewport = (elem, options) => {
  options = options || {}
  if(elem.style.position == 'fixed' || elem.style.position == 'sticky' || options.force){
    return false
    // return options.considerPositionFixedAsViewport || false;
  }
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const isFalsy = (statement) => {
  statement = String(statement)
  return statement === 'false' || statement === 'null' || statement === 'undefined' || statement === '0'
}

const isTruthy = (statement) => {
  return !isFalsy(statement)
}

export {
  addClass, removeClass, insertStylesheet, insertScript, secondsToTime, isBlank, isObject, isElementHidden, isTruthy, isFalsy, deepMerge, getElementHeight, isInViewport
}